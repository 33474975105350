import React, { ForwardRefRenderFunction, forwardRef, useCallback, ChangeEvent, ForwardedRef } from 'react'
import MaskedInput, { Mask } from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import cx from 'classnames'
import styles from './styles.module.scss'
import { Wrapper, WrapperProps } from 'components/Core/CoreInput'

// https://github.com/text-mask/text-mask/tree/master/addons/#createnumbermask
const currencyMask: Mask = createNumberMask({ allowDecimal: true, allowLeadingZeroes: true })

function mergeRefs<T>(...refs: React.Ref<T>[]): React.Ref<T> {
  return (instance: T | null) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(instance)
      } else if (ref && 'current' in ref) {
        ;(ref as React.MutableRefObject<T | null>).current = instance
      }
    })
  }
}

export type MoneyInputProps = WrapperProps & JSX.IntrinsicElements['input']

const MoneyInput: ForwardRefRenderFunction<HTMLInputElement, MoneyInputProps> = (
  {
    value,
    hint,
    hintClassName,
    kind,
    className,
    onChange,
    onChangeText,
    id,
    label,
    placeholder,
    borderType,
    elementSize,
    showFieldRequired,
    spacing = 'wide',
    ...props
  },
  ref
) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event)
      onChangeText?.(event.target.value.replace(/[$,]/g, ''))
    },
    [onChange, onChangeText]
  )

  const renderInput = useCallback(
    (innerRef: ForwardedRef<HTMLInputElement>, props: JSX.IntrinsicElements['input']) => {
      const combinedref = mergeRefs(ref, innerRef)
      return <input ref={combinedref} {...props} />
    },
    [ref]
  )

  return (
    <Wrapper
      hint={hint}
      hintClassName={hintClassName}
      label={label}
      borderType={borderType}
      kind={kind}
      elementSize={elementSize}
      id={id}
      className={cx(styles.wrapper, className)}
      showFieldRequired={showFieldRequired}
      spacing={spacing}
    >
      <MaskedInput
        mask={currencyMask}
        id={id}
        placeholder={placeholder || label}
        value={value}
        className={styles.input}
        onChange={handleChange}
        inputMode="numeric"
        {...props}
        ref={undefined}
        render={renderInput as any}
      />
    </Wrapper>
  )
}

export default forwardRef(MoneyInput)
