import React, { ForwardRefRenderFunction, forwardRef, useCallback, ChangeEvent } from 'react'
import cx from 'classnames'
import styles from './styles.module.scss'
import ExpandMore from 'svgs/expand-more'
import { Wrapper, WrapperProps } from 'components/Core/CoreInput'

type SelectProps = WrapperProps & { allowBlank?: boolean; placeholder?: string } & JSX.IntrinsicElements['select']

/** Renders a native select. For fancy rendered options, use <CoreInput.FancySelect> */
const Select: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  {
    hint,
    hintClassName,
    kind,
    className,
    onChange,
    onChangeText,
    label,
    placeholder,
    id,
    allowBlank,
    children,
    borderType,
    elementSize,
    showFieldRequired,
    spacing = 'wide',
    ...props
  },
  ref
) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      onChange?.(event)
      onChangeText?.(event.target.value)
    },
    [onChange, onChangeText]
  )

  return (
    <Wrapper
      hint={hint}
      hintClassName={hintClassName}
      label={label}
      id={id}
      kind={kind}
      borderType={borderType}
      elementSize={elementSize}
      className={cx(styles.wrapper, className)}
      showFieldRequired={showFieldRequired}
      spacing={spacing}
    >
      <ExpandMore className={styles.accessory} />
      <select id={id} className={styles.input} onChange={handleChange} {...props} ref={ref}>
        {(allowBlank || !!placeholder) && (
          <option value="" disabled={!allowBlank} hidden={!allowBlank}>
            {allowBlank ? '' : placeholder}
          </option>
        )}
        {children}
      </select>
    </Wrapper>
  )
}

export default forwardRef(Select)
