import React, { PropsWithChildren, FC } from 'react'
import cx from 'classnames'
import CoreText from '../CoreText'
import styles from './styles.module.scss'
import Input from 'components/Core/CoreInput/Input'
import MoneyInput from 'components/Core/CoreInput/MoneyInput'
import TextArea from 'components/Core/CoreInput/TextArea'
import Select from 'components/Core/CoreInput/Select'
import FancySelect from 'components/Core/CoreInput/FancySelect'
import Address from 'components/Core/CoreInput/Address'
import Check from 'components/Core/CoreInput/Check'
import Radio from 'components/Core/CoreInput/Radio'

export interface WrapperProps {
  className?: string
  hint?: string
  hintClassName?: string
  onChangeText?: (value: string) => void
  kind?: 'enabled' | 'alert' | 'positive' | 'disabled'
  id?: string
  label?: string
  borderType?: 'bounded' | 'boundless'
  elementSize?: 'l' | 'xl'
  showFieldRequired?: boolean
  spacing?: 'tight' | 'wide'
}

export const Wrapper: FC<PropsWithChildren<WrapperProps>> = ({
  hint,
  hintClassName,
  className,
  kind = 'enabled',
  children,
  id,
  label,
  borderType = 'bounded',
  elementSize = 'xl',
  showFieldRequired = false,
  spacing = 'wide',
}) => {
  return (
    <>
      <div
        className={cx(
          styles.wrapper,
          kind,
          borderType,
          elementSize,
          spacing,
          { [styles.showRequired]: showFieldRequired },
          className
        )}
      >
        <div className={styles.inputWrapper}>
          {children}
          <label htmlFor={id}>{label}</label>
        </div>
        {hint && (
          <CoreText.Paragraph size="s" color="tertiaryBlack" weight="light" className={cx(styles.hint, hintClassName)}>
            {hint}
          </CoreText.Paragraph>
        )}
      </div>
    </>
  )
}

const CoreInput = {
  Text: Input,
  Money: MoneyInput,
  TextArea,
  Select,
  FancySelect,
  Address,
  Check,
  Radio,
}

export default CoreInput
