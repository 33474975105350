import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  FocusEvent,
  ChangeEvent,
  useImperativeHandle,
  useRef,
} from 'react'

import cx from 'classnames'

import styles from './styles.module.scss'
import { Wrapper, WrapperProps } from 'components/Core/CoreInput'

type TextAreaProps = WrapperProps & JSX.IntrinsicElements['textarea'] & { autoResizeOnFocus?: boolean }

const TextArea: ForwardRefRenderFunction<HTMLTextAreaElement, TextAreaProps> = (
  {
    hint,
    hintClassName,
    kind,
    className,
    onChange,
    onChangeText,
    rows: rowsProp,
    label,
    elementSize,
    placeholder,
    borderType,
    id,
    autoResizeOnFocus,
    showFieldRequired,
    spacing,
    ...props
  },
  ref
) => {
  const internalRef = useRef<HTMLTextAreaElement>(null)
  useImperativeHandle(ref, () => internalRef.current as HTMLTextAreaElement)
  const rows = rowsProp || 4

  const setAutoHeight = useCallback((element: HTMLTextAreaElement) => {
    element.style.height = 'auto'
    const computedStyle = window.getComputedStyle(element)
    const borderTopWidth = parseFloat(computedStyle.borderTopWidth)
    const borderBottomWidth = parseFloat(computedStyle.borderBottomWidth)
    element.style.height = `${element.scrollHeight + borderTopWidth + borderBottomWidth}px`
  }, [])

  const removeHeight = useCallback((element) => {
    element.style.height = ''
  }, [])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (autoResizeOnFocus) setAutoHeight(event.target)
      onChange?.(event)
      onChangeText?.(event.target.value)
    },
    [autoResizeOnFocus, setAutoHeight, onChange, onChangeText]
  )

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      props.onFocus?.(event)
      if (autoResizeOnFocus) setAutoHeight(event.target)
    },
    [autoResizeOnFocus, setAutoHeight, props]
  )

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      props.onBlur?.(event)
      if (autoResizeOnFocus) removeHeight(event.target)
    },
    [autoResizeOnFocus, props, removeHeight]
  )

  return (
    <Wrapper
      hint={hint}
      hintClassName={hintClassName}
      label={label}
      id={id}
      kind={kind}
      borderType={borderType}
      elementSize={elementSize}
      className={cx(styles.wrapper, styles.ta, className)}
      showFieldRequired={showFieldRequired}
      spacing={spacing}
    >
      <textarea
        id={id}
        placeholder={placeholder || label}
        className={styles.input}
        rows={rows}
        onChange={handleChange}
        ref={internalRef}
        {...props}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </Wrapper>
  )
}

export default forwardRef(TextArea)
