import React, { ForwardRefRenderFunction, InputHTMLAttributes, forwardRef } from 'react'

import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked'

const Radio: ForwardRefRenderFunction<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & { label: string; id: string; showFieldRequired?: boolean }
> = ({ label, id, checked, showFieldRequired, ...props }, ref) => {
  return (
    <div className="tw-flex tw-items-center tw-rounded tw-p-2 hover:tw-bg-neutrals-100">
      <div className="tw-relative tw-flex tw-items-center tw-justify-center">
        <input
          type="radio"
          checked={checked}
          {...props}
          id={id}
          ref={ref}
          className="tw-inset-0 tw-absolute tw-opacity-0"
        />
        {checked ? (
          <RadioButtonChecked className="!tw-size-4 tw-relative tw-pointer-events-none tw-text-green-700" />
        ) : (
          <RadioButtonUnchecked className="!tw-size-4 tw-relative tw-pointer-events-none" />
        )}
      </div>
      <label className="tw-grow tw-pl-1 tw-select-none" htmlFor={id}>
        {label}
        {showFieldRequired && <span className="tw-text-red-600 tw-ml-1">{'*'}</span>}
      </label>
    </div>
  )
}

export default forwardRef(Radio)
