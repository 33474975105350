import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useState,
  ChangeEvent,
  useImperativeHandle,
  useRef,
} from 'react'
import cx from 'classnames'
import { formatPhoneNumberValue, useFormattedPhoneInput } from 'components/Core/useFormattedPhoneInput'
import styles from './styles.module.scss'
import { Wrapper, WrapperProps } from 'components/Core/CoreInput'

const InnerPhoneInput: ForwardRefRenderFunction<
  HTMLInputElement,
  { onChangeText?: (value: string) => void } & JSX.IntrinsicElements['input']
> = ({ onChange, onChangeText, value, ...props }, forwardedRef) => {
  const [phoneValue, setPhoneValue] = useState<string>(formatPhoneNumberValue(value ? String(value) : ''))

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event)
    },
    [onChange]
  )

  const handleChangedText = useCallback(
    (value: string) => {
      setPhoneValue(value)
      onChangeText?.(value)
    },
    [onChangeText]
  )

  const handlePhoneReset = useCallback(() => {
    setPhoneValue('')
    onChangeText?.('undefined')
  }, [onChangeText])

  const { formattedValue, ref, handlePhoneNumberChanged } = useFormattedPhoneInput(
    (value || phoneValue)?.toString() || '',
    handleChange,
    handleChangedText
  )

  useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement)

  return (
    <input
      ref={ref}
      className={styles.input}
      onChange={handlePhoneNumberChanged}
      onReset={handlePhoneReset}
      {...props}
      value={formattedValue}
    />
  )
}

const PhoneInput = forwardRef(InnerPhoneInput)

export type InputProps = WrapperProps & JSX.IntrinsicElements['input'] & { popOnClick?: boolean }

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    value,
    hint,
    hintClassName,
    kind,
    className,
    onChange,
    onChangeText,
    id,
    label,
    placeholder,
    borderType,
    elementSize,
    showFieldRequired,
    spacing,
    popOnClick,
    ...props
  },
  ref
) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event)
      onChangeText?.(event.target.value)
    },
    [onChange, onChangeText]
  )

  // Create a local ref for the input.
  const localInputRef = useRef<HTMLInputElement>(null)

  // Combine the forwarded ref and the local ref.
  useImperativeHandle(ref, () => localInputRef.current as HTMLInputElement)

  // Define a click handler for date inputs.
  const handleDateClick = useCallback(
    (e) => {
      if (localInputRef.current && popOnClick) {
        // If available, use the experimental .showPicker() method.
        if (typeof localInputRef.current.showPicker === 'function') {
          localInputRef.current.showPicker()
        } else {
          // Otherwise, focus the input (which may trigger the picker in some browsers).
          localInputRef.current.focus()
        }
      }

      if (props.onClick) props.onClick(e)
    },
    [props, popOnClick]
  )

  return (
    <Wrapper
      hint={hint}
      hintClassName={hintClassName}
      label={label}
      borderType={borderType}
      kind={kind}
      elementSize={elementSize}
      id={id}
      className={cx(styles.wrapper, className)}
      showFieldRequired={showFieldRequired}
      spacing={spacing}
    >
      {props.type == 'tel' ? (
        <PhoneInput
          id={id}
          placeholder={placeholder || label}
          value={value}
          className={styles.input}
          onChange={handleChange}
          onChangeText={onChangeText}
          {...props}
          ref={localInputRef}
        />
      ) : (
        <input
          id={id}
          placeholder={placeholder || label}
          value={value}
          className={styles.input}
          onChange={handleChange}
          {...props}
          ref={localInputRef}
          onClick={props.type == 'date' || props.type == 'datetime-local' ? handleDateClick : undefined}
        />
      )}
    </Wrapper>
  )
}

export default forwardRef(Input)
